import { useEffect } from 'react';
import { MyButton } from '../../components'
import * as imgs from '../../img'
export default function Part1() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    const bgImg = {
        backgroundImage: 'url(' + imgs.bg1 + ')',
    }
    return (
        <>
            <div id='home' className='topBanner anchor' style={bgImg}>
                <div className='cHeading'>
                    <h1>L'avenir commence ici :</h1>
                    <MyButton isMinimal='scroll' title='Découvrir' type={1} icon='chevron-down' link='#whoRWe' />
                </div>
            </div>
            <section className='whoAreWe' >
                <div className='anchor' id='whoRWe' />
                <div className='sectionTitle'>
                    <h1>Qui sommes <span>NOUS</span> ?</h1>
                    <div className='decoration'>
                        <div className='x' />
                    </div>
                </div>
                <div className='content'>
                    <div className='thumb' data-aos='fade-up'>
                        <img src={imgs.img1} alt='' />
                        <div className='pattern' />
                    </div>
                    <div className='desc' id='ZXS'>
                        <p>
                            <span>BM RÉALISATION</span>, une entreprise spécialisée dans la construction TCE, l'aménagement, la rénovation et l'agencement, offre un accompagnement complet, de la conception à la livraison de votre projet. Forte de son expertise, <span>BM RÉALISATION</span> s'engage à répondre à vos besoins avec professionnalisme et efficacité. Notre équipe dédiée met tout en œuvre pour garantir la réussite de chaque étape de votre projet. Nous nous efforçons de fournir des solutions innovantes et de qualité, assurant ainsi la satisfaction de nos clients tout au long du processus. Fiable et engagée.
                        </p>
                        <p>Une équipe pluridisciplinaire est à votre disposition :</p>
                        <ul>
                            <li>Assurer la qualité des travaux</li>
                            <li>Assurer l'optimisation des coûts</li>
                            <li>Garantir les délais de livraison</li>
                        </ul>
                    </div>
                </div>
                <div className='buttonCenterX'>
                    <MyButton title='Accéder au BET' icon='chevron-left' link='/projects/home' />
                    <MyButton title='Consulter nos activités' icon='chevron-right' link='/activities' />
                </div>
            </section>
        </>
    )
}
