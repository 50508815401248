import * as imgs from '../img'
import { NavLink } from 'react-router-dom';
function Footer({ second }) {
    return (
        <footer className='footer-distributed'>
            <div className='footer-left'>
                <img src={second ? imgs.logo2 : imgs.logo1} alt='' />
                {second === true ? <p className='footer-links'>
                    <NavLink to='/suppliers' className='link-1'>Acceuil</NavLink>
                    <NavLink to='/companies'>Consultation & Expertise</NavLink>
                    <NavLink to='/companies'>Activités</NavLink>
                    <NavLink to='/companies'>Nos Valeurs</NavLink>
                </p> : <p className='footer-links'>
                    <NavLink to='/suppliers' className='link-1'>Acceuil</NavLink>
                    <NavLink to='/companies'>Études & Conception</NavLink>
                    <NavLink to='/companies'>OPC / AMO</NavLink>
                    <NavLink to='/companies'>QS - Économie de projets</NavLink>
                </p>}
                <p className='footer-company-name'>{second === false ? 'BM Réalisations' : 'BM Projects'} © 2023</p>
            </div>
            <div className='footer-center'>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-location-dot'></i></div>
                    <p><span>46, Rue El batinia, Immeuble la Rose III, Maarif,</span> Casablanca, Maroc</p>
                </div>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-phone'></i></div>
                    <p>+212 (0) 661 415 937</p>
                </div>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-envelope'></i></div>
                    <p><a href={`mailto:${second === false ? 'bm.realis@gmail.com' : 'bilal.bmprojects@gmail.com'}`}>{second === false ? 'bm.realis@gmail.com' : 'bilal.bmprojects@gmail.com'}</a></p>
                </div>
            </div>
            <div className='footer-right'>
                <p className='footer-company-about'>
                    <span>{second === false ? 'BM Réalisations' : 'BM Projects'}</span>
                    Grâce à notre expérience, notre expertise et la diversité des projets réalisés, notre équipe analyse vos besoins et vous assure un conseil objectif.
                </p>
            </div>
        </footer >
    )
}

export default Footer