import { useEffect } from 'react';
import { MyButton } from '../../components';
import * as imgs from '../../img'
export default function XPart1() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <>
            <section id='home1'>
                <div className='content' data-aos='fade-left'>
                    <h1><span>Qualité et Expertise Technique</span> pour des résultats exceptionnels.</h1>
                    <MyButton isMinimal='scroll' title='Voir plus' icon='chevron-down' type={1} link='/projects/home#whyWe' />
                </div>
                <div className='bg'>
                    <img src={imgs.img38} alt='' />
                </div>
            </section>
            <section className='whyWe'>
                <div className='anchor' id='whyWe' />
                <h1>Pourquoi BM Projects ?</h1>
                <div className='content'>
                    <p data-aos='fade-right'>Nous sommes convaincus que nous nous distinguons par plusieurs aspects qui en font le choix idéal pour vos projets. Tout d'abord, notre équipe est composée d'experts hautement qualifiés et expérimentés, spécialisés dans une gamme étendue de domaines techniques. Leur expertise, combinée à notre approche collaborative et personnalisée, nous permet de répondre de manière précise et efficace à vos besoins spécifiques. De plus, notre engagement envers l'innovation et la qualité se reflète dans chacun de nos projets, où nous mettons un point d'honneur à intégrer les dernières technologies et les meilleures pratiques de l'industrie. Enfin, notre solide réputation, basée sur des réalisations réussies et des relations de confiance avec nos clients, atteste de notre capacité à fournir des résultats exceptionnels et à dépasser les attentes. En choisissant notre bureau d'études, vous optez pour l'excellence, le professionnalisme et l'engagement envers la réussite de votre projet.</p>
                    <img src={imgs.img24} alt='' data-aos='fade-left' data-aos-delay='500'/>
                </div>
            </section>
            <div className='buttonCenterX Y'>
                <MyButton title='Accéder à BM Réalisations' icon='chevron-left' link='/' />
                <MyButton title='Voir nos services' icon='chevron-right' link='/projects/studies' />
            </div>
            <div style={{ height: 100 }} />
        </>
    )
}
