import { Icon } from '../../components'
import { useEffect } from 'react';
export default function Part2() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <section className='advice' >
            <div className='sectionTitle'>
                <h1>Conseil & Expertise</h1>
                <div className='decoration'>
                    <div className='x' />
                </div>
            </div>
            <div className='content'>
                <div className='desc1'>
                    <p>Grâce à notre expérience <span>de plus de 30 ans</span>, notre expertise et la diversité des projets réalisés, notre équipe analyse vos besoins et vous assure un conseil objectif tout au long de votre projet pour vous apporter les meilleurs solutions. En plus, nous vous assurons une meilleure qualité-prix de vos ouvrages.</p>
                </div>
                <div className='desc2'>
                    <div className='serviceCard' data-aos='zoom-in-up' >
                        <div className='cardIcon'>
                            <div className='iconN i6'>
                                <Icon type='user-group' />
                            </div>
                            <h3>Une Équipe compétente</h3>
                        </div>
                        <div className='contentX'>
                            <p>Notre entreprise dispose d'une équipe très compétente, dotée d'une expertise exceptionnelle dans divers domaines.</p>
                        </div>
                    </div>
                    <div className='serviceCard' data-aos='zoom-in-up' data-aos-delay='300' >
                        <div className='cardIcon'>
                            <div className='iconN i6'>
                                <Icon type='arrow-up-right-dots' />
                            </div>
                            <h3>Expérience Impressionnante</h3>
                        </div>
                        <div className='contentX'>
                            <p>Avec une expertise accumulée sur une décennie, notre société offre un service de qualité basé sur une expérience solide et éprouvée.</p>
                        </div>
                    </div>
                    <div className='serviceCard' data-aos='zoom-in-up' data-aos-delay='600' >
                        <div className='cardIcon'>
                            <div className='iconN i6'>
                                <Icon type='building-circle-check' />
                            </div>
                            <h3>Service de Qualité</h3>
                        </div>
                        <div className='contentX anchor' id='activities'>
                            <p>Grâce à notre engagement envers l'excellence, notre société offre un service irréprochable, assurant une expérience client optimale à chaque étape.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}