import { Route, Routes} from 'react-router-dom';
import Home from './realisation';
import Home1 from './projects';
import './style/reset.css';
import './style/style.css';
import './style/aos.css';
import AOS from 'aos';
import { useEffect } from 'react';
function App() {
	useEffect(() => {
		AOS.init({
			delay: 200,
			once: false,
			easing: 'ease-in-out',
		});
	  }, []);
    return (
		<>
			<Routes>
				<Route path='*' element={<Home/>} />
				<Route path='/projects/*' element={<Home1/>} />
			</Routes>
		</>	
    );
}

export default App;
