import Icon from './Icon'
import { NavLink } from 'react-router-dom'
export default function MyButton({ isMinimal, title, icon, type, href, link }) {
    const types = ['', 'outlined', 'secondColor', 'expanded']
    if (isMinimal === true) {
        return (
            <button className={'minBttn ' + types[type ? type - 1 : 0]}>
                <h4>{title}</h4>
            </button >
        )
    } else if (isMinimal === 'scroll') {
        return (
            <a href={link} className={'bttn ' + types[type ? type - 1 : 0]} onClick={href} data-aos='fade-down'>
                <h4>{title}</h4>
                <Icon type={icon} width={20} />
            </a>
        )
    } else {
        return (
            <NavLink to={link} className={'bttn ' + types[type ? type - 1 : 0]} onClick={href} data-aos='fade-down'>
                <h4>{title}</h4>
                <Icon type={icon} width={20} />
            </NavLink>
        )
    }
}
