import { useEffect } from 'react';
import { Icon } from '../../components'
import * as imgs from '../../img'
export default function Part5() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <section className='contact'>
            <h1>Contacter Nous</h1>
            <p>N'hésitez pas à nous contacter pour toute question, commentaire ou demande de renseignements. Notre équipe se fera un plaisir de vous aider dans les meilleurs délais. </p>
            <div className='screen'>
                <div className='decor'>
                    <img src={imgs.img23} alt='' />
                </div>
                <div className='content'>
                    <div className='topC'>
                        <h2>Nous sommes à l'écoute</h2>
                    </div>
                    <div className='bottomC'>
                        <a className='cRow' target='blank' href='https://wa.me/212661415937'>
                            <div className='iconN'>
                                <Icon type='whatsapp' brand={true} />
                            </div>
                            <h3>+2126.61.41.59.37</h3>
                        </a>
                        <a className='cRow' href='tel:212661415937'>
                            <div className='iconN'>
                                <Icon type='phone' />
                            </div>
                            <h3>+2126.61.41.59.37</h3>
                        </a>
                        <a className='cRow' href='mailto:bm.realis@gmail.com'>
                            <div className='iconN'>
                                <Icon type='envelope' />
                            </div>
                            <h3>bm.realis@gmail.com</h3>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
