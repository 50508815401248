import { Footer, Header2 } from './components';
import { Route, Routes, useNavigate } from 'react-router-dom';
import * as p from './pages'
import { useEffect } from 'react';
function Redirect() {
    const history = useNavigate()
    useEffect(() => {
        history('/projects/home')
    }, [history]);
    return <></>
}
export default function Home1() {
    document.documentElement.style.setProperty('--color1', '#D0A54D');
    document.documentElement.style.setProperty('--color2', '#262562');
    return (
        <>
            <Header2 />
            <Routes>
                <Route exact path='' element={<Redirect />} />
                <Route exact path='home' element={<p.XPart1 />} />
                <Route exact path='studies' element={<p.XPart2 />} />
                <Route exact path='functions' element={<p.XPart3 />} />
                <Route exact path='contact' element={<p.XPart4 />} />
            </Routes>
            <Footer second={true} />
        </>
    )
}
