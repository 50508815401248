import * as imgs from '../../img'
import { useEffect } from 'react';
export default function Part3() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <section className='activities'>
            <div className='sectionTitle'>
                <h1>Nos Activités</h1>
                <div className='decoration'>
                    <div className='x' />
                </div>
            </div>
            <div className='content'>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>1</h2>
                    </div>
                    <h1>Construction TCE<div className='anchor' id='sec0' /></h1>
                </div>
                <div className='desc'>
                    <p><span>BM RÉALISATION</span> se distingue par son savoir-faire et son engagement à fournir des solutions exceptionnelles dans le domaine du « Tout Corps d'État ». Forte de son expertise et de son expérience avérée, notre entreprise est en mesure de mener à bien l'ensemble des activités liées à la construction, de la conception à la réalisation finale. Nous mettons un point d'honneur à offrir à nos clients des prestations complètes, répondant de manière exhaustive à leurs besoins spécifiques en matière de construction. Que ce soit pour des projets de grande envergure ou des travaux plus spécialisés, <span>BM RÉALISATION</span> s'engage à garantir la satisfaction de ses clients en fournissant des services de qualité et en respectant les normes les plus élevées de l'industrie. Voici des exemples comprenant notre expertise :</p>
                    <h1>1. Gros oeuvres <div className='anchor' id='sec1' /></h1>
                    <h2>a. Maçonnerie :</h2>
                    <p>C'est la construction des structures en assemblant des matériaux comme la brique, la pierre, ou le parpaing, généralement avec du mortier, pour former des murs, des cloisons ou d'autres éléments de construction.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img9} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img10} alt='' /></div>
                    </div>
                    <h2>b. Fondations : </h2>
                    <p>Les fondations soutiennent tout le poids de la structure et le transmettent au sol de manière stable, avec des différents types selon les caractéristiques du sol et les charges à supporter.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img7} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img8} alt='' /></div>
                    </div>
                    <h2>c. Béton Armé : </h2>
                    <p>C'est la base solide de nos constructions, solide et durable. Avec notre expertise et notre choix méticuleux de matériaux, nous nous assurons que chaque projet est fiable et résistant.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img5} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img6} alt='' /></div>
                    </div>
                    <h2>d. Charpente métallique : </h2>
                    <p>Nous concevons des charpentes métalliques, des structures robustes et sûres pour divers projets de construction, en proposant des solutions efficaces, garantissant la solidité et la fiabilité des bâtiments.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img11} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img12} alt='' /></div>
                    </div>
                    <h1>2. Étanchéité : <div className='anchor' id='sec2' /></h1>
                    <p>Est traitée avec rigueur et expertise, garantissant la durabilité et la fiabilité des constructions que nous concevons. Nous sélectionnons des matériaux de pointe et appliquons des techniques avancées pour assurer une protection efficace contre les infiltrations d'eau.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img3} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img4} alt='' /></div>
                    </div>
                    <h1>3. Menuiserie : <div className='anchor' id='sec3' /></h1>
                    <h2>a. Bois :</h2>
                    <p>C'est un élément essentiel pour apporter confort, esthétique et fonctionnalité à nos projets. En travaillant avec des artisans qualifiés et en sélectionnant des matériaux de qualité, nous assurons la réalisation de menuiseries sur mesure répondant aux besoins spécifiques de chaque espace.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img21} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img22} alt='' /></div>
                    </div>
                    <h2>b. Aluminium : </h2>
                    <p>L'aluminium est un matériau polyvalent et innovant que nous intégrons dans nos conceptions avec précision. Nous exploitons ses qualités de légèreté, de durabilité et de malléabilité pour créer des solutions architecturales et structurelles modernes et performantes.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img17} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img18} alt='' /></div>
                    </div>
                    <h2>c. Fer : </h2>
                    <p>Le fer est une entité spécialisée dans l'analyse, la conception et la réalisation de projets techniques. Son rôle principal est d'apporter des solutions innovantes et efficaces aux défis d'ingénierie rencontrés dans divers domaines industriels. Il contribue à la conception et à l'optimisation de produits, de structures ou de systèmes.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img19} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img20} alt='' /></div>
                    </div>
                </div>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>2</h2>
                    </div>
                    <h1>Aménagement & Rénovation <div className='anchor' id='sec4' /></h1>
                </div>
                <div className='desc'>
                    <p>Spécialisée dans l'aménagement, la rénovation et les travaux de second œuvre, <span>BM RÉALISATION</span> se distingue par sa capacité à offrir des solutions personnalisées, fonctionnelles, ingénieuses et innovantes pour vos projets de rénovations. Forte de son expertise dans le domaine, notre entreprise s'engage à apporter une valeur ajoutée à chaque étape du processus, depuis la conception jusqu'à la réalisation finale. Que vous ayez besoin d'une rénovation complète ou de travaux spécifiques, nous mettons en œuvre notre savoir-faire pour créer des espaces à la fois esthétiques et fonctionnels, répondant aux besoins uniques de nos clients. Chez <span>BM RÉALISATION</span>, nous croyons en l'excellence dans l'aménagement, garantissant ainsi la réussite de vos projets de rénovation. Y compris:</p>
                    <h2>1. Aménagement de bureaux et d'espaces commerciaux : <div className='anchor' id='sec5' /></h2>
                    <p>Les entreprises peuvent entreprendre des projets d'aménagement pour moderniser leurs bureaux ou leurs locaux commerciaux. Cela peut inclure la réorganisation de l'espace, l'installation de nouvelles infrastructures, la mise en place de solutions de connectivité, etc.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img15} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img16} alt='' /></div>
                    </div>
                    <h2>2. La rénovation résidentielle : <div className='anchor' id='sec6' /></h2>
                    <p>Cela comprend la rénovation de maisons individuelles ou d'appartements. Les projets de rénovation peuvent inclure la mise à jour des cuisines, des salles de bains, le remplacement des revêtements de sol, des peintures, ainsi que l'amélioration de l'efficacité énergétique des bâtiments.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img13} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img14} alt='' /></div>
                    </div>
                </div>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>3</h2>
                    </div>
                    <h1>Agencement Clés en main <div className='anchor' id='sec7' /></h1>
                </div>
                <div className='desc anchor' id='values'>
                    <p><span>BM RÉALISATION</span> se positionne en tant que partenaire privilégié pour l'aménagement complet des espaces commerciaux et professionnels, que ce soit pour des magasins, des boutiques indépendantes ou d'autres structures similaires. Notre approche se distingue par la proposition d'une solution « clé en main », offrant une gestion intégrale du projet, depuis la conception initiale jusqu'à la réalisation finale. Nous nous engageons à assurer une prestation de qualité exceptionnelle, en respectant scrupuleusement les délais convenus et en suivant les normes les plus rigoureuses de l'industrie. Chez <span>BM RÉALISATION</span>, nous comprenons l'importance d'un environnement commercial bien aménagé, et c'est pourquoi nous mettons tout en œuvre pour fournir des solutions efficaces et esthétiquement agréables qui répondent aux attentes spécifiques de nos clients.</p>
                </div>
            </div>
        </section>
    )
}