import { useState } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom'
import * as imgs from '../img'
import { Icon } from '.'
import React from 'react';
import { Dropdown } from 'antd';
export function Header1() {
    function linkCreator(x) {
        var link = document.createElement('a');
        link.href = x;
        document.body.appendChild(link);
        link.click();
    }
    const items = [
        {
            key: '1',
            label: 'Construction TCE',
            onTitleClick: () => { linkCreator('/activities#sec0') },
            children: [
                {
                    key: '1-1',
                    label: 'Gros oeuvres',
                    onClick: () => { linkCreator('/activities#sec1') },
                },
                {
                    key: '1-2',
                    label: 'Étanchéité',
                    onClick: () => { linkCreator('/activities#sec2') }
                },
                {
                    key: '1-3',
                    label: 'Menuiserie',
                    onClick: () => { linkCreator('/activities#sec3') }
                },
            ],
        },
        {
            key: '2',
            label: 'Aménagement & Rénovation',
            onTitleClick: () => { linkCreator('/activities#sec4') },
            children: [
                {
                    key: '2-1',
                    label: 'Aménagement de bureaux et d\'espaces commerciaux',
                    onClick: () => { linkCreator('/activities#sec5') },
                },
                {
                    key: '2-2',
                    label: 'La rénovation résidentielle',
                    onClick: () => { linkCreator('/activities#sec6') },
                },
            ],
        },
        {
            key: '3',
            label: 'Agencement Clés en main',
            onClick: () => { linkCreator('/activities#sec7') },
        },
    ];
    const [menu, setMenu] = useState(false);
    const history = useNavigate()
    const location = useLocation()
    const menuBarStyle = {
        right: menu ? 0 : '-100%',
        boxShadow: menu ? '-10px 20px 20px #0009' : 'none',
    }
    return (
        <nav>
            <div className='logo' onClick={() => { history('/') }}>
                <img src={imgs.logo1} alt='' />
            </div>
            <div className='headings'>
                <ul>
                    <NavLink className={location === '/consulting' ? 'n linkHead active' : 'n linkHead'} to='/consulting'>Consultation & Expertise</NavLink>
                    <Dropdown menu={{ items }}>
                        <NavLink className={location === '/activities' ? 'n linkHead active' : 'n linkHead'} to='/activities'>
                            Activités
                            <Icon type='caret-down' />
                        </NavLink>
                    </Dropdown>
                    <NavLink className={location === '/values' ? 'n linkHead active' : 'n linkHead'} to='/values'>Nos Valeurs</NavLink>
                    <NavLink className={location === '/contact' ? 'n linkHead active' : 'n linkHead'} to='/contact'>Contacter Nous</NavLink>
                </ul>
            </div>
            <div className='menuToggler' onClick={() => { setMenu(!menu) }}>
                {!menu && <Icon type='bars' />}
                {menu && <Icon type='xmark' />}
            </div>
            <div className='menuBar' style={menuBarStyle}>
                <div className='logo'>
                    <img src={imgs.logo1} alt='' onClick={() => { history('/'); setMenu(false) }} />
                </div>
                <div className='links'>
                    <p id='this' onClick={() => { linkCreator('/consulting') }}>Consultation & Expertise</p>
                    <div className='separator' />
                    <div className='accordion accordion-flush' id='biggerAccordion'>
                        <div className='accordion-item'>
                            <h2 className='accordion-header'>
                                <button className='accordion-button' type='button' data-bs-toggle='collapse' data-bs-target='#bigAccordion' aria-expanded='true' aria-controls='bigAccordion'>
                                    <p onClick={() => { linkCreator('/activities') }}>Nos Activités</p>
                                </button>
                            </h2>
                            <div id='bigAccordion' className='accordion-collapse collapse'>
                                <div className='accordion accordion-flush' id='accordion'>
                                    <div className='accordion-item'>
                                        <h2 className='accordion-header'>
                                            <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse1' aria-controls='collapse1'>
                                                Construction TCE
                                            </button>
                                        </h2>
                                        <div id='collapse1' className='accordion-collapse collapse' data-bs-parent='#accordion1'>
                                            <a href='/activities#sec1' onClick={() => { setMenu(false) }}>Gros Oeuvres</a>
                                            <div className='separator mini' />
                                            <a href='/activities#sec2' onClick={() => { setMenu(false) }}>Étanchéité</a>
                                            <div className='separator mini' />
                                            <a href='/activities#sec3' onClick={() => { setMenu(false) }}>Menuiserie</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='separator' />
                                <div className='accordion accordion-flush' id='accordion'>
                                    <div className='accordion-item'>
                                        <h2 className='accordion-header'>
                                            <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#collapse2' aria-controls='collapse2' >
                                                Aménagement & Rénovation
                                            </button>
                                        </h2>
                                        <div id='collapse2' className='accordion-collapse collapse' data-bs-parent='#accordion2'>
                                            <a href='/activities#sec5' onClick={() => { setMenu(false) }}>Aménagement de bureaux et d'espaces commerciaux</a>
                                            <div className='separator mini' />
                                            <a href='/activities#sec6' onClick={() => { setMenu(false) }}>La rénovation résidentielle</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='separator' />
                    <p id='this' onClick={() => { linkCreator('/values'); setMenu(false) }}>Nos Valeurs</p>
                    <div className='separator' />
                    <p id='this' onClick={() => { linkCreator('/contact'); setMenu(false) }}>Contacter Nous</p>
                </div>
            </div>
        </nav>
    )
}
export function Header2() {
    const [menu, setMenu] = useState(false);
    const history = useNavigate()
    const menuBarStyle = {
        right: menu ? 0 : '-100%',
        boxShadow: menu ? '-10px 20px 20px #0009' : 'none',
    }
    return (
        <nav className='second'>
            <div className='logo' onClick={() => { history('/projects/home') }}>
                <img src={imgs.logo2} alt='' />
            </div>
            <div className='headings'>
                <ul>
                    <NavLink className='n linkHead ' to='home'>Acceuil</NavLink>
                    <NavLink className='n linkHead ' to='studies'>Études & Conception</NavLink>
                    <NavLink className='n linkHead ' to='functions'>OPC / AMO</NavLink>
                    <NavLink className='n linkHead ' to='contact'>Contact</NavLink>
                </ul>
            </div>
            <div className='menuToggler' onClick={() => { setMenu(!menu) }}>
                {!menu && <Icon type='bars' />}
                {menu && <Icon type='xmark' />}
            </div>
            <div className='menuBar' style={menuBarStyle}>
                <div className='logo'>
                    <img src={imgs.logo2} alt='' onClick={() => { history('/'); setMenu(false) }} />
                </div>
                <div className='links'>
                    <div className='linkRow expanded'>
                        <NavLink onClick={() => { setMenu(false) }} to='/projects'>Acceuil</NavLink>
                    </div>
                    <div className='linkRow expanded'>
                        <NavLink onClick={() => { setMenu(false) }} to='/projects/studies'>Études & Conception</NavLink>
                    </div>
                    <div className='linkRow expanded'>
                        <NavLink onClick={() => { setMenu(false) }} to='/projects/functions'>OPC / AMO</NavLink>
                    </div>
                    <div className='linkRow expanded'>
                        <NavLink onClick={() => { setMenu(false) }} to='/projects/contact'>Contact</NavLink>
                    </div>
                </div>
            </div>
        </nav>
    )
}
