import { useEffect } from 'react'
import * as imgs from '../../img'
export default function XPart2() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <section id='study'>
            <div style={{ height: 100 }} />
            <div className='sectionTitle1'>
                <h1>Études & Conceptions</h1>
            </div>
            <div className='content'>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>1</h2>
                    </div>
                    <h1>Structure bâtiment et ouvrage d'art<div className='anchor' id='sec0' /></h1>
                </div>
                <div className='desc anchor'>
                    <p>Notre expertise en structures de bâtiments et d'ouvrages d'art repose sur une équipe d'ingénieurs civils spécialisés, dévoués à la conception, à l'analyse et à la construction d'une gamme diversifiée de structures. Nous nous distinguons par notre approche innovante et notre maîtrise des normes techniques et réglementaires. Que ce soit pour des projets résidentiels, commerciaux ou des ouvrages d'art complexes tels que des ponts et des tunnels, notre équipe met en œuvre des solutions techniques optimisées, adaptées aux défis environnementaux et fonctionnels de chaque projet. En collaboration étroite avec nos clients, nous nous engageons à fournir des réalisations durables et esthétiques, contribuant ainsi à un environnement bâti plus sûr et plus fonctionnel.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img26} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img36} alt='' /></div>
                    </div>
                </div>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>2</h2>
                    </div>
                    <h1>Assainissement<div className='anchor' id='sec7' /></h1>
                </div>
                <div className='desc anchor'>
                    <p>Dans le domaine de l'assainissement, nous nous démarquons par notre capacité à élaborer des stratégies complètes pour la gestion des eaux usées et pluviales. Notre équipe d'experts en assainissement s'engage à concevoir et mettre en place des systèmes novateurs, adaptés aux besoins spécifiques de chaque projet. En collaboration étroite avec les parties prenantes, nous développons des solutions durables, intégrant des technologies avancées et des pratiques respectueuses de l'environnement. Qu'il s'agisse de la conception de réseaux d'assainissement, de la mise en œuvre de stations d'épuration ou de la gestion des eaux pluviales, notre approche vise à garantir la qualité de l'eau, la protection des écosystèmes et le bien-être des populations.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img27} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img37} alt='' /></div>
                    </div>
                </div>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>3</h2>
                    </div>
                    <h1>Électricité<div className='anchor' id='sec7' /><br /><p>CFO - CFA (Détection incendie, GTC, Contrôle d'accès,...).</p></h1>
                </div>
                <div className='desc anchor'>
                    <p>L'Électricité, notamment dans les installations CFO (Courants Forts) et CFA (Courants Faibles), notre expertise s'étend à la conception, à la mise en œuvre et à la gestion de systèmes complexes tels que la détection incendie, la gestion technique centralisée (GTC) et le contrôle d'accès. Nos ingénieurs spécialisés mettent en œuvre des solutions innovantes et sécurisées pour répondre aux besoins spécifiques de chaque projet, en intégrant les dernières avancées technologiques et en respectant les normes et réglementations en vigueur. Que ce soit pour des bâtiments résidentiels, commerciaux, industriels ou institutionnels, notre approche personnalisée garantit la fiabilité, la performance et la sécurité des installations électriques, contribuant ainsi à la protection des biens et des personnes, tout en assurant une gestion efficace des ressources énergétiques.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img28} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img29} alt='' /></div>
                    </div>
                </div>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>4</h2>
                    </div>
                    <h1>Fluides<div className='anchor' id='sec7' /><br /><p>Plomberie, protection incendie, climatisation, désenfumage, fluides spéciaux.</p></h1>
                </div>
                <div className='desc anchor'>
                    <p>Dans notre expertise concernant les fluides, incluant la plomberie, la protection incendie, la climatisation, le désenfumage et les fluides spéciaux, nous démontrons notre capacité à concevoir, installer et entretenir des systèmes fluidiques complexes répondant aux exigences les plus strictes en matière de sécurité et de performance. Notre engagement se traduit par une approche intégrée qui prend en compte les normes réglementaires, les technologies de pointe et les besoins spécifiques de chaque projet. Nos équipes spécialisées collaborent étroitement avec les clients pour concevoir des solutions sur mesure, garantissant une distribution efficace des fluides, une protection incendie fiable, une climatisation optimale, un désenfumage efficace et la gestion appropriée des fluides spéciaux. Nous sommes déterminés à assurer le confort, la sécurité et la durabilité des installations fluidiques, contribuant ainsi à créer des environnements sûrs, sains et fonctionnels pour nos clients et leurs occupants.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img34} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img35} alt='' /></div>
                    </div>
                </div>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>5</h2>
                    </div>
                    <h1>Téléphonie<div className='anchor' id='sec7' /></h1><br />
                </div>
                <div className='desc anchor'>
                    <p>Dans le domaine de la téléphonie, notre expertise s'étend à la conception, à la mise en place et à la maintenance de systèmes de communication adaptés aux besoins variés de nos clients. Nous sommes spécialisés dans la fourniture de solutions de téléphonie innovantes, allant des réseaux traditionnels aux systèmes de téléphonie sur IP (VoIP). Notre équipe d'experts travaille en étroite collaboration avec les clients pour concevoir des infrastructures de communication fiables et évolutives, intégrant des fonctionnalités telles que la voix sur IP, la messagerie unifiée et la visioconférence. Nous mettons un point d'honneur à fournir des solutions personnalisées, adaptées aux besoins spécifiques de chaque entreprise, afin d'optimiser l'efficacité opérationnelle et de favoriser la collaboration et l'interaction au sein de l'organisation.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img30} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img31} alt='' /></div>
                    </div>
                </div>
                <div className='titleNum'>
                    <div className='num'>
                        <h2>6</h2>
                    </div>
                    <h1>Routes & Voiries<div className='anchor' id='sec7' /></h1><br />
                </div>
                <div className='desc anchor'>
                    <p>Dans le cadre de nos activités concernant les routes et voiries, nous mettons en avant notre savoir-faire en matière de planification, de conception et de construction d'infrastructures routières. Notre engagement se reflète dans notre capacité à développer des solutions innovantes et adaptées à chaque projet, en prenant en compte les contraintes géographiques, environnementales et budgétaires spécifiques à chaque situation. Notre équipe de spécialistes en génie civil travaille en étroite collaboration avec les autorités locales, les urbanistes et les parties prenantes pour concevoir des réseaux routiers sûrs et efficaces, intégrant les dernières avancées en matière de sécurité et de gestion du trafic. Grâce à notre approche intégrée et à notre souci constant de qualité et de durabilité, nous contribuons à améliorer la fluidité des déplacements, à favoriser le développement économique et à renforcer les liens au sein des communautés locales.</p>
                    <div className='illu'>
                        <div className='thun' data-aos='fade-right'><img src={imgs.img32} alt='' /></div>
                        <div className='thun' data-aos='fade-left'><img data-aos-delay='500' src={imgs.img33} alt='' /></div>
                    </div>
                </div>
            </div>
            <div style={{ height: 100 }} />
        </section>
    )
}
