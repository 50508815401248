import { Icon } from '../../components'
import { useEffect } from 'react';
export default function Part4() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <section className='values'>
            <div className='sectionTitle'>
                <h1>Nos valeurs</h1>
                <div className='decoration'>
                    <div className='x' />
                </div>
            </div>
            <div className='content'>
                <div className='lilCard n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='briefcase' />
                        </div>
                        <h1>L'engagement</h1>
                    </div>
                    <p>L'engagement dans notre bureau d'études est au cœur de notre approche. Nous nous investissons pleinement dans chaque projet, faisant preuve de détermination, de professionnalisme et de responsabilité envers nos clients. Notre engagement se traduit par la recherche constante de solutions innovantes, assurant ainsi la réussite et la satisfaction dans la réalisation de chaque mission qui nous est confiée.</p>
                </div>
                <div className='lilCard n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='lightbulb' />
                        </div>
                        <h1>Le conseil</h1>
                    </div>
                    <p>Dans notre bureau d'études, le conseil constitue un pilier fondamental de notre approche. Nous mettons à profit notre expertise et notre expérience pour fournir des orientations pertinentes et des solutions adaptées à nos clients. Notre objectif est d'accompagner nos partenaires dans leurs décisions stratégiques et opérationnelles, en veillant à ce qu'ils atteignent leurs objectifs avec succès.</p>
                </div>
                <div className='lilCard n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='headset' />
                        </div>
                        <h1>L'écoute</h1>
                    </div>
                    <p>L'écoute est une compétence essentielle dans notre bureau d'études. Nous accordons une importance particulière à comprendre les besoins, les objectifs et les préoccupations de nos clients. En prêtant une attention attentive, nous sommes en mesure de formuler des solutions sur mesure, répondant de manière précise aux défis spécifiques de chaque projet. Cette écoute active contribue à établir une collaboration efficace.</p>
                </div>
                <div className='lilCard n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='clock-rotate-left' />
                        </div>
                        <h1>La réactivité</h1>
                    </div>
                    <p>La réactivité est un principe clé de notre approche. Nous nous efforçons de répondre rapidement et efficacement aux besoins et aux demandes de nos clients. En anticipant les défis et en agissant de manière proactive, nous assurons un suivi rigoureux des projets et une communication fluide à chaque étape. Cette réactivité garantit une gestion efficiente des projets et renforce la confiance de nos clients.</p>
                </div>
            </div>
        </section>
    )
}