import { Header1, Footer } from './components'
import { Route, Routes } from 'react-router-dom';
import * as p from './pages'
export default function Home() {
    document.documentElement.style.setProperty('--color1', '#CC7727');
    document.documentElement.style.setProperty('--color2', '#472E26');
    return (
        <>
            <Header1 />
            <Routes>
                <Route exact path='/' element={<p.Part1 />} />
                <Route exact path='/consulting' element={<p.Part2 />} />
                <Route exact path='/activities' element={<p.Part3 />} />
                <Route exact path='/values' element={<p.Part4 />} />
                <Route exact path='/contact' element={<p.Part5 />} />
            </Routes>
            <Footer second={false} />
        </>
    )
}
