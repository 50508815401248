import { useEffect } from 'react';
import * as imgs from '../../img'
import { Icon } from '../../components';
export default function XPart3() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }, []);
    return (
        <>
            <div style={{ height: 100 }} />
            <section id='opc'>
                <div className='sectionTitle1'>
                    <h1>OPC / AMO</h1>
                </div>
                <div className='content'>
                    <div className='thumb'>
                        <img src={imgs.img2} alt='' />
                    </div>
                    <div className='desc'>
                        <ul>
                            <li>Coordination des études.</li>
                            <li>Coordination et suivi des travaux.</li>
                            <li>Organisation et animation des réunions.</li>
                            <li>Ordonnancement et Planification des travaux.</li>
                            <li>Contrôle et supervision des travaux.</li>
                            <li>Mise en place des procédures de suivi.</li>
                            <li>Reporting et rapports de suivi du projet.</li>
                            <li>Archivage et classement des documents.</li>
                            <li>Planification des essais et des réceptions.</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id='qs'>
                <div className='sectionTitle1' >
                    <h1>QS - Économie des projets</h1>
                </div>
                <div className='lilCard2 n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='ruler' />
                        </div>
                        <h3>Réalisation des avant-métrés</h3>
                    </div>
                    <p className='qsC'>Les avant-métrés, exécutés avec minutie, établissent une base essentielle pour une planification et une exécution optimales du projet. Notre dévouement à l'excellence dans cette étape se reflète par une gestion proactive des risques, une détection précoce des défis et une utilisation optimisée des ressources. Grâce à une collaboration étroite avec nos clients et l'utilisation des dernières technologies, nous fournissons des estimations précises, fondamentales pour le succès de chaque projet d'ingénierie civile. Cette phase préliminaire, où la précision est cruciale, bénéficie de l'expertise incomparable de nos ingénieurs civils.</p>
                </div>
                <div className='lilCard2 n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='folder-open' />
                        </div>
                        <h3>Suivi administratif des marchés</h3>
                    </div>
                    <p className='qsC'>Au sein de notre bureau d'études techniques, le suivi administratif des marchés est un élément clé de notre approche globale de gestion de projets. Nous veillons à une surveillance rigoureuse de chaque aspect administratif, garantissant conformité, transparence et responsabilité. De la documentation des contrats à la gestion des paiements et des délais, notre équipe s'assure d'une coordination fluide entre toutes les parties, favorisant une réalisation harmonieuse des projets. Grâce à notre expertise et à notre compréhension des exigences légales, nous garantissons une gestion efficace des marchés, soutenue par des systèmes de suivi solides et une communication transparente pour assurer une gouvernance et une intégrité exemplaires.</p>
                </div>
                <div className='lilCard2 n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='magnifying-glass' />
                        </div>
                        <h3>Dépouillement et analyse des offres</h3>
                    </div>
                    <p className='qsC'>Le dépouillement et l'analyse des offres sont des étapes essentielles pour sélectionner les fournisseurs et entrepreneurs dans nos projets d'ingénierie civile. Cette phase, menée avec soin et professionnalisme, consiste à évaluer minutieusement chaque proposition pour en juger la pertinence et la faisabilité. Notre équipe se concentre sur l'analyse approfondie des aspects financiers, techniques et contractuels des offres, en respectant les exigences du projet et les normes établies. Nous appliquons des principes d'impartialité, de transparence et de rigueur pour garantir un processus équitable, visant à identifier les soumissionnaires les plus qualifiés, capables de respecter les délais et les budgets. Cette étape cruciale contribue au succès et à la qualité de chaque projet d'ingénierie civile que nous entreprenons.</p>
                </div>
                <div className='lilCard2 n' data-aos='zoom-in-down'>
                    <div className='topCard'>
                        <div className='iconN'>
                            <Icon type='list' />
                        </div>
                        <h3>Établissement des services</h3>
                    </div>
                    <p className='qsC'>Dans notre entreprise, nous offrons une gamme complète de services pour la gestion et l'exécution des projets d'ingénierie civile. Nous réalisons les Documents de Consultation des Entreprises (DCE) en veillant à la clarté des spécifications et à la précision des exigences techniques. Nos équipes, dédiées aux estimations Tous Corps d'État (TCE), travaillent avec soin pour fournir des évaluations financières détaillées, garantissant une planification budgétaire rigoureuse. Notre expertise couvre également la gestion des marchés et des avenants, en assurant la conformité contractuelle et la transparence tout au long du processus. Les métrés des travaux sont effectués avec précision, garantissant une évaluation exhaustive des quantités et coûts associés. Nous fournissons également des décomptes transparents et conformes aux normes en vigueur. Ces services sont les fondements de notre engagement envers l'excellence et la qualité dans la gestion de chaque projet d'ingénierie civile.</p>
                </div>
            </section>
        </>
    )
}
